<script lang='ts'>
  // import Border from "./border.svelte";
  import { onMount, onDestroy } from 'svelte';

  let isTouchDevice = false;
  if ("ontouchstart" in document.documentElement){
    isTouchDevice = true;
  }

  let hovered = false;
  let touched = false;
  let fading = false;
  let hoverEl = null;
  let events = [];

  function createListener(element, eventName, func){
    element.addEventListener(eventName, func);

    return () => {
      element.removeEventListener(eventName, func);
    };
  }

  onMount(() => {
    if(isTouchDevice){
      var timeout: any = null;

      events.push(createListener(hoverEl, 'touchstart', () => {
        touched = true;

        timeout = setTimeout(() => {
          hovered = true;
        }, 500);
      }));

      events.push(createListener(hoverEl, 'touchend', () => {
        if(!hovered){
          clearTimeout(timeout);
        }
        touched = false;
      }));

      events.push(createListener(document, 'touchstart', () => {
        if(hovered){
          hovered = false;
          fading = true;

          setTimeout(() => {
            fading = false;
          }, 300);
        }
      }));
    }
    else{
      events.push(createListener(hoverEl, 'mouseenter', () => {
        hovered = true;
        fading = false;
      }));

      events.push(createListener(hoverEl, 'mouseleave', () => {
        hovered = false;
        fading = true;

        setTimeout(() => {
          fading = false;
        }, 300);
      }));
    }
  });

  onDestroy(() => {
    events.forEach(event => {
      event();
    });
    events.length = 0;
  });
</script>

<div class='hover-area' bind:this={hoverEl}>
  {#if hovered || fading}
    <div class='shadow {fading ? "fading":""}'></div>

    <div class='hover-container {fading ? "fading":""}'>
      <!-- <Border borderType='fog'/> -->

      <div class='hover'>
        <slot />
      </div>
    </div>
  {/if}
</div>

<style lang='less'>
  .hover-area{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 111;

    .hover-container{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      z-index: 111;

      border-image: url('../../assets/shadow.png') 10 / 10px;
      border-width: 7px;
      border-style: solid;

      animation: fadeIn 0.3s;
      animation-fill-mode: forwards;

      &.fading{
        animation: fadeOut 0.3s;
      }

      .hover{
        background-color: var(--background-colour);
        outline: 1px solid black;
        border-radius: 4px;
        line-height: 1;
        padding: 20px;
      }
    }

    .shadow{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      background: rgba(0,0,0,0.3);
      z-index: 11;
      animation: fadeIn 0.3s;
      animation-fill-mode: forwards;

      &.fading{
        animation: fadeOut 0.3s;
      }
    }
  }

  @keyframes fadeIn{
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
  @keyframes fadeOut{
    from{
      opacity: 1;
    }
    to{
      opacity: 0;
    }
  }
</style>