<script lang="ts">
  import { screens, version, glossary, playerTruth} from '../store';
  import { openOptions, openTutorial } from './modals/modals';
  import { isSavedGame, loadGame } from './utils/savefile';
  import Border from './components/border.svelte';
  import Chrome from './components/chrome.svelte';
  import Frame from './components/frame.svelte';
  import { onMount } from 'svelte';
  import pinch from './utils/pinch';

  const startGame = () => {
    screens.set('playerselect');
  }

  const openFarm = () => {
    screens.set('farm');
  }

  function openGlossary(){
    glossary.open.set(true);
  }

  function openOpts(){
    openOptions({});
  }

  function continueGame(){
    var data = loadGame();
    playerTruth.set(data.player);

    screens.set('map', {map: data.map});
  }

  function quit(){
    window.close();
  }
</script>

<div class='main'>
  <Frame --width='min(500px, calc(90vw - 80px))'>
    <div class='content'>
      <h1>Odam</h1>
      <h2>Dice Builder RPG</h2>

      <!-- {#if isSavedGame()}
        <button class='btn start' on:click={continueGame}>
          Continue
        </button>
      {/if}

      <button class='btn start' on:click={startGame}>
        Start Game
      </button>

      <button class='btn start' on:click={openOpts}>
        Options
      </button>

      <button class='btn start' on:click={openGlossary}>
      </button> -->

      <Chrome click={startGame} chroming='gold' edge='round' thickness='thick' gloss={true}>
        <div class='button'>Start Game</div>
      </Chrome>

      <Chrome click={openOpts} chroming='obsidian' edge='round' thickness='thick' gloss={true}>
        <div class='button'>Options</div>
      </Chrome>

      <Chrome click={openGlossary} chroming='obsidian' edge='round' thickness='thick' gloss={true}>
        <div class='button'>Glossary</div>
      </Chrome>

      <Chrome click={quit} chroming='obsidian' edge='round' thickness='thick' gloss={true}>
        <div class='button'>Quit</div>
      </Chrome>

      <!-- <button class='btn start' on:click={openFarm}>
        farm
      </button> -->

      <!-- <button class='btn start' on:click={startGame}>
        Credits
      </button> -->

      <!-- <button class='btn start' on:click={quit}>
        Quit
      </button> -->
    </div>
  </Frame>
</div>

<p style='text-align=left;'>v{version}</p>

<style lang='less'>
  @import "../defines.less";

  .main{
    height: 100%;
    width: 100%;
    background-image: url('../assets/backgrounds/main-splash.webp');
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-items: center;
    display: grid;
    grid-template-rows: 1fr auto;

    .content{
      display: grid;
      grid-gap: 10px;
      align-items: center;
      text-align: center;
      color: #ffffd5;
    }
  }

  .button{
    font-size: 32px;
    padding: 4px;
    color: #ffffd5;
  }

  p{
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px 20px;
  }
</style>