<script lang='ts'>
  import { inventoryData } from './modals';
  import Icon from '../icon/icon.svelte';
  import type { Player } from '../models/player.type';
  import type { Item } from '../models/item.type';
  import Hover from '../components/hover.svelte';
  import Chrome from '../components/chrome.svelte';
  import Frame from '../components/frame.svelte';

  let onConfirm = null;
  let items: Array<Item> = [];
  let player: Player;

  inventoryData.subscribe(value => {
    if(value){
      items = value.inventory;
      player = value.inventory;

      onConfirm = value.callback;
    }
    else{
      onConfirm = null;
    }
  });

  const close = () => {
    if(onConfirm){
      onConfirm();
    }
    inventoryData.set(null);
  }

</script>

{#if $inventoryData}
<div class='modal-container inventory'>
  <div class='modal'>
    <Frame>
      <div class='header'>
        <h1>Inventory</h1>
      </div>

      <div class='body'>
        {#each items as item}
          <div class='item'>
            <div class='icon'>
              <Icon name={item.icon} />
              {#if item.bonusIcon}
                <Icon name={item.bonusIcon} class='bonus'/>
              {/if}
              <!-- <h3 class='name'>{item.title}</h3> -->

              <Hover>
                <div class='icon-container'>
                  <Icon name={item.icon} />
                  {#if item.bonusIcon}
                    <Icon name={item.bonusIcon} class='bonus'/>
                  {/if}
                </div>

                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </Hover>
            </div>
            <!-- <div class='description'>{item.description}</div> -->
          </div>
        {/each}
      </div>

      <div class='functions'>
        <Chrome chroming='gold' edge='circle' thickness='thin' click={close}>
          <div class='button'>Close</div>
        </Chrome>
      </div>
    </Frame>
  </div>
</div>
{/if}

<style lang="less">
  @import "../../defines.less";

  :global(.modal-container.inventory){
    --icon-size: 80px;
    --icon-gap: 10px;
    --bonus-icon-size: 30px;
  }
  @media @medium{
    :global(.modal-container.inventory){
      --icon-size: 60px;
      --icon-gap: 5px;
      --bonus-icon-size: 25px;
    }
  }
  @media @small{
    :global(.modal-container.inventory){
      --icon-size: 50px;
      --icon-gap: 5px;
      --bonus-icon-size: 20px;
    }
  }

  .body{
    display: flex;
    grid-gap: var(--icon-gap);

    min-width: min(500px, 90vw);

    .item{
      width: var(--icon-size);

      .icon-container{
        width: var(--icon-size);
        margin: auto;
        position: relative;
      }
      .icon{
        position: relative;
        width: var(--icon-size);
        height: var(--icon-size);

        :global(.bonus){
          position: absolute;
          top: 0;
          left: 0;
          --icon-size: var(--bonus-icon-size);
          background: rgba(0,0,0,0.5);
          border-radius: 8px;
          box-shadow: 0 0 4px 4px rgba(0,0,0,0.5);
        }
      }
    }
  }
</style>