<script lang='ts'>
  import { createEventDispatcher, onMount } from 'svelte';
  import { ammoData } from './modals';
  import Icon from '../icon/icon.svelte';
  // const dispatch = createEventDispatcher();
  import { keywords } from '../models/keywords';
  import Hover from '../components/hover.svelte';
  import Frame from '../components/frame.svelte';
  import Chrome from '../components/chrome.svelte';

  let onConfirm = null;
  let die = null;

  ammoData.subscribe(value => {
    if(value && value.callback){
      onConfirm = value.callback;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    // die.selected = false;
    if(onConfirm){
      onConfirm();
    }
    ammoData.set(null);
  }

  /* function chooseDie(selectedDie){
    $ammoData = $ammoData;
  } */
</script>

{#if $ammoData}
<div class='modal-container'>
  <div class='modal'>
    <Frame>
      <div class='header'>
        <h1>Ammo</h1>
      </div>

      <div class='body'>
        <Icon name='prevarrow' width='20px' height='20px' class='icon arrow'/>

        {#each $ammoData.ammo as ammo}
        <div class='icon-container'>
          <Icon name='ammo' width='40px' height='40px' class='icon'/>
          <Icon name={ammo} width='20px' height='20px' class='icon bonus'/>

          <Hover>
            <h3>{ammo} Ammo</h3>
            <!-- <p>ammo ({ammo})</p> -->
            <p>{keywords[`ammo (${ammo})`]}</p>
          </Hover>
        </div>
        {/each}

      </div>

      <div class='functions'>
        <Chrome chroming='gold' edge='circle' thickness='thin' click={confirm}>
          <div class='button'>Confirm</div>
        </Chrome>
      </div>
    </Frame>
  </div>
</div>
{/if}

<style lang="less">
  .modal{
    min-width: 50vw;
  }

  h1, h3, p{
    text-transform: capitalize;
  }

  .body{
    display: flex;
    justify-content: center;

  }

  .icon-container{
    width: var(--icon-size);
    margin: 0 var(--dice-gap);
    position: relative;
  }

  .icon-container :global(.icon){
    position: relative;
    z-index: 2;
  }
  .icon-container :global(.icon.bonus){
    position: absolute;
    z-index: 3;
    top: -5px;
    left: -5px;
  }
  :global(.icon.arrow){
    padding: 10px;
  }
</style>