<script lang='ts'>
  export let borderType: 'shadow' | 'fog' | 'solid' | 'shine' = 'solid';

  let colourDark = '#2f1606';
  let colourMid = '#4f4f4f';
  let colourLight = '#8f8f8f';
  let colourWhite = '#ffffd5';
</script>

<div class='frame'>
  <!-- <div class='frame-inner'> -->

    <svg class='corner top left' version="1.1" viewBox="0 0 87 148" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="Gradient1" x1="0" x2="1" y1="1" y2="0">
          <stop offset="0%" stop-color="{colourMid}" />
          <stop offset="70%" stop-color="{colourLight}" />
          <stop offset="80%" stop-color="{colourWhite}" />
          <stop offset="90%" stop-color="{colourLight}" />
          <stop offset="100%" stop-color="{colourLight}" />
        </linearGradient>
        <linearGradient id="Gradient2" x1="0" x2="1" y1="0" y2="1">
          <stop offset="0%" stop-color="{colourLight}" />
          <stop offset="10%" stop-color="{colourWhite}" />
          <stop offset="20%" stop-color="{colourLight}" />
          <stop offset="100%" stop-color="{colourMid}" />
        </linearGradient>
      </defs>
      <path fill='url(#Gradient1)' stroke='rgba(0,0,0,0.5)' stroke-width='5px' d="m0.010253 0.018132 86.986-0.018759c-2.1972 9.9517-3.8219 24.662-15.679 25.264-19.861 1.0402-28.22 5.8961-37.348 14.367-14.838 15.228-7.2617 53.232-8.9315 82.506-0.79828 15.608-11.673 24.409-25.042 25.864z"/>
    </svg>

    <svg class='corner top right' version="1.1" viewBox="0 0 87 148" xmlns="http://www.w3.org/2000/svg">
      <path fill='url(#Gradient2)' stroke='rgba(0,0,0,0.5)' stroke-width='5px' d="m0.010253 0.018132 86.986-0.018759c-2.1972 9.9517-3.8219 24.662-15.679 25.264-19.861 1.0402-28.22 5.8961-37.348 14.367-14.838 15.228-7.2617 53.232-8.9315 82.506-0.79828 15.608-11.673 24.409-25.042 25.864z"/>
    </svg>

    <svg class='corner bottom left' version="1.1" viewBox="0 0 111 127" xmlns="http://www.w3.org/2000/svg">
      <path fill='url(#Gradient1)' stroke='rgba(0,0,0,0.5)' stroke-width='5px' d="m0-0.0082682-0.0020671 127.01 110.99 0.00569c0.15656-15.82-8.8329-11.637-36.282-14.471-15.34-1.9619-30.227-9.1183-27.526-21.892 2.3327-8.7152 1.7106-10.537 0.58863-12.367-1.4735-2.1248-7.3936-4.0522-17.138-0.25665-7.1089 1.9837-19.133 8.6263-15.873-45.301 0.84718-26.068 0.43481-32.73-14.757-32.727z"/>
    </svg>

    <svg class='corner bottom right' version="1.1" viewBox="0 0 111 127" xmlns="http://www.w3.org/2000/svg">
      <path fill='url(#Gradient2)' stroke='rgba(0,0,0,0.5)' stroke-width='5px' d="m0-0.0082682-0.0020671 127.01 110.99 0.00569c0.15656-15.82-8.8329-11.637-36.282-14.471-15.34-1.9619-30.227-9.1183-27.526-21.892 2.3327-8.7152 1.7106-10.537 0.58863-12.367-1.4735-2.1248-7.3936-4.0522-17.138-0.25665-7.1089 1.9837-19.133 8.6263-15.873-45.301 0.84718-26.068 0.43481-32.73-14.757-32.727z"/>
    </svg>

    <slot/>
  <!-- </div> -->
</div>

<style lang='less'>
  .frame {
    background: radial-gradient(rgba(0,0,0, 0.25), rgba(0,0,0, 0.8));
    padding: 5px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(45deg, #2f1606 0%, #692f1f 20%, #692f1f 50%, #87442b 80%, #ffffd5 90%, #87442b 100%) 1;

    width: var(--width);
    // border: 2px solid var(--border-colour);
    /* min-width: min(400px, 90vw); */
    /* max-width: min(500px, 90vw); */
    backdrop-filter: blur(5px);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
    position: relative;
    padding: 40px;

    /* &:before,
    &:after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      font-size: 14px;
      color: var(--border-colour);
      border: 2px solid var(--border-colour);
      line-height: 12px;
      top: 5px;
      text-align: center;
      z-index: 1;
    }

    &:before {
      left: 5px;
    }

    &:after {
      right: 5px;
    } */

    .corner{
      position: absolute;
      width: auto;
      height: 60px;
    }
    .top{
      top: -6px;
    }
    .left{
      left: -6px;
    }
    .right{
      right: -6px;
      transform: scaleX(-1);
    }
    .bottom{
      bottom: -6px;
    }

    /* .frame-inner {
      position: relative;
      border: 2px solid var(--border-colour);
      padding: 40px;
      display: grid;
      grid-gap: 10px;
      align-items: center;
      box-shadow: inset 0 0 50px 50px rgba(0, 0, 0, 0.5);

      .btn {
        font-size: 32px;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
        font-size: 14px;
        color: var(--border-colour);
        border-top: 2px solid var(--border-colour);
        line-height: 12px;
        bottom: -2px;
        text-align: center;
      }

      &:before {
        left: -1px;
        border-right: 2px solid var(--border-colour);
      }

      &:after {
        right: -1px;
        border-left: 2px solid var(--border-colour);
      }
    } */
  }
</style>