<script lang='ts'>
  import {getIcon, icons} from "./icons";
  export let name: string;
  export let width = '100%';
  export let height = '100%';

  export let colour = 'white';

  let selectedIcon: string | undefined = icons[name];
  let displayIcon: HTMLElement;
  let displayIconString: string;

  function parseIcon(icon: string){
    let parser = new DOMParser();
    let doc = parser.parseFromString(selectedIcon, "image/svg+xml");

    displayIcon = doc.documentElement;
    displayIcon.setAttribute('width', width);
    displayIcon.setAttribute('height', height);

    displayIcon.setAttribute('fill', colour);
    // displayIcon.style.fill = colour;
    displayIcon.style['z-index'] = 1;

    displayIcon.classList.add('icon');
    if($$props.class){
      let classes = $$props.class.split(' ');
      classes.forEach((c) => {
        displayIcon.classList.add(c);
      });
    }

    displayIconString = displayIcon.outerHTML;
  }

  $: if (name !== '') {
    selectedIcon = icons[name].icon;
    if(!selectedIcon){
      console.error('No icon for:', name);
    }
    else{
      parseIcon(selectedIcon);
    }
  }
  else{
    selectedIcon = icons.blank.icon;
    parseIcon(selectedIcon);
  }
</script>

<!-- width={width} -->
<!-- height={height} -->
<!-- <svg
  class={$$props.class}
  style="
    width: {width != '100%'? width : 'var(--icon-size)'};
    height: {height != '100%'? height : 'var(--icon-size)'};
    fill: {colour}"
  viewBox="0 0 500 500">{@html displayIcon.svg}</svg> -->

  {@html displayIconString}

<style lang='less'>
  :global(.icon){
    stroke: black;
    stroke-width: 10px;
    z-index: 1;

    width: var(--icon-size);
    height: var(--icon-size);
  }
</style>
