<script lang='ts'>
  import DiceTray from '../dice-tray/dice-tray.svelte';
  import { selectFaceData } from './modals';
  import Frame from '../components/frame.svelte';
  import Chrome from '../components/chrome.svelte';

  let onConfirm = null;
  let face = null;
  let faces = [];

  selectFaceData.subscribe(value => {
    if(value && value.callback){
      onConfirm = value.callback;

      for(var i = 0; i < 6; i++){
        faces[i] = value.die.clone();
        faces[i].activeFace = i;
        faces[i].id = 'x'+i;
        faces[i].selected = false;
      }
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm(face);
    }
    selectFaceData.set(null);
  }

  function chooseDie(selectedDie){
    /*if(selectedDie.used){
      return;
    }*/
    faces.forEach(die => {
      die.selected = false;
    });

    selectedDie.selected = true;
    face = selectedDie.activeFace;

    faces = faces;
  }
</script>

{#if $selectFaceData}
<div class='modal-container'>
  <div class='modal'>
    <Frame>
      <div class='header'>
        <h1>Select a Die</h1>
      </div>

      <div class='body'>
        <DiceTray
          dice={faces}
          clickEvent="{(die) => {chooseDie(die)}}"
          class='horizontal'
        />
      </div>

      <div class='functions'>
        <Chrome chroming='gold' edge='circle' thickness='thin' click={confirm}>
          <div class='button'>Confirm</div>
        </Chrome>
      </div>
    </Frame>
  </div>
</div>
{/if}

<style lang="less">

</style>