<script lang='ts'>
  import { promptData } from './modals';
  import Frame from '../components/frame.svelte';
  import Chrome from '../components/chrome.svelte';

  let onConfirm = null;
  let promptText = '';

  promptData.subscribe(value => {
    if(value && value.callbackConfirm){
      onConfirm = value.callbackConfirm;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm(promptText);
    }
    promptData.set(null);
    promptText = '';
  }

  const cancel = () => {
    promptData.set(null);
    promptText = '';
  }
</script>

{#if $promptData}
<div class='modal-container'>
  <div class='modal'>
    <Frame>
      {#if $promptData.title}
        <div class='header'>
          <h1>{$promptData.title}</h1>
        </div>
      {/if}

      {#if $promptData.text}
        <div class='body'>
          <p>{$promptData.text}</p>

          <textarea bind:value={promptText} placeholder='Enter report here...'></textarea>
        </div>
      {/if}

      <div class='functions'>
         <Chrome chroming='obsidian' edge='circle' thickness='thin' click={cancel}>
          <div class='button'>Cancel</div>
        </Chrome>
        <Chrome chroming='gold' edge='circle' thickness='thin' click={confirm}>
          <div class='button'>Confirm</div>
        </Chrome>
      </div>
    </Frame>
  </div>
</div>
{/if}

<style lang='less'>
  p{
    margin-bottom: 20px;
  }
  textarea{
    width: 100%;
    height: 100px;
  }
</style>