<script lang='ts'>
  import { confirmData } from './modals';
  import Frame from '../components/frame.svelte';
  import Chrome from '../components/chrome.svelte';

  let onConfirm = null;

  confirmData.subscribe(value => {
    if(value && value.callbackConfirm){
      onConfirm = value.callbackConfirm;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm();
    }
    confirmData.set(null);
  }

  const cancel = () => {
    confirmData.set(null);
  }
</script>

{#if $confirmData}
<div class='modal-container'>
  <div class='modal'>
    <Frame>
      {#if $confirmData.title}
        <div class='header'>
          <h1>{$confirmData.title}</h1>
        </div>
      {/if}

      {#if $confirmData.text}
        <div class='body'>
          <p>{$confirmData.text}</p>
        </div>
      {/if}

      <div class='functions'>
        <Chrome chroming='obsidian' edge='circle' thickness='thin' click={cancel}>
          <div class='button'>Cancel</div>
        </Chrome>
        <Chrome chroming='gold' edge='circle' thickness='thin' click={confirm}>
          <div class='button'>Confirm</div>
        </Chrome>
      </div>
    </Frame>
  </div>
</div>
{/if}

<style lang='less'>
</style>